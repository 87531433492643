<template>
  <VariableLaporanManajemenForm mode="Ubah" module="Variable Laporan Manajemen"> </VariableLaporanManajemenForm>
</template>

<script>
import VariableLaporanManajemenForm from './form';

const VariableLaporanManajemenUpdate = {
  name: 'VariableLaporanManajemenUpdate',
  components: { VariableLaporanManajemenForm },
};

export default VariableLaporanManajemenUpdate;
</script>
